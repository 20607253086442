class SimpleSlider {

    constructor(selector) {
        this.selector = selector;
        this.slides = this.selector.querySelectorAll('[data-slide]');

        this.navigation = this.selector.querySelector('[data-element="navigation"]');

        this._refreshSlider = this._refreshSlider.bind(this);
        this._playSlider = this._playSlider.bind(this);
        this._stopSlider = this._stopSlider.bind(this);
        this._changeSlide = this._changeSlide.bind(this);

        this.interval = null;

        this.activeSlide = 0;
        this.slideCount = this.slides.length;
        if (this.slideCount > 0) {
            let i = 0;
            this.slides.forEach(slide => {
                if (i === 0) {
                    slide.classList.add('active');
                }
                slide.setAttribute('data-id', i);
                i++;
            });
            if (this.navigation !== null) {
                let j = 0;
                this.dots = this.navigation.querySelectorAll('[data-element="dot"]');
                this.dots.forEach(dot => {
                    if (j === 0) {
                        dot.classList.add('active');
                    }
                    dot.addEventListener('click', this._changeSlide);
                    j++;
                })
            }
        }

        this._playSlider();

        this.selector.addEventListener('mouseover', this._stopSlider);
        this.selector.addEventListener('mouseout', this._playSlider);
    }

    _changeSlide(event) {
        event.preventDefault();
        this.activeSlide = parseInt(event.target.getAttribute('data-id'));
        this._stopSlider();
        this._setActiveSlide();
        this._playSlider();

    }

    _playSlider() {
        this.interval = window.setInterval(this._refreshSlider, 4000);
    }

    _stopSlider() {
        window.clearInterval(this.interval);
        this.interval = null;
    }

    _refreshSlider() {
        this.activeSlide++;
        if (this.activeSlide >= this.slideCount) {
            this.activeSlide = 0;
        }
        this._setActiveSlide();
    }

    _setActiveSlide() {
        this.slides.forEach(slide => {
            let id = parseInt(slide.getAttribute('data-id'));
            if (id !== this.activeSlide) slide.classList.remove('active');
            else slide.classList.add('active');
        });
        if (this.navigation !== null) {
            if (this.dots.length > 0) {
                this.dots.forEach(dot => {
                    let id = parseInt(dot.getAttribute('data-id'));
                    if (id !== this.activeSlide) dot.classList.remove('active');
                    else dot.classList.add('active');
                })
            }
        }
    }


}

export default SimpleSlider;